<template>
  <FormCard
    :title="composition.name ? composition.name : 'Novo item de composição'"
    :status="composition.status"
    @cancel="$router.push({ name: 'composition' })"
    @save="validate"
  >
    <v-form
      class="px-5 mt-5"
      ref="form"
    >
      <v-row dense>
        <v-col
          cols="12"
          md="12"
        >
          <v-row dense>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="composition.name"
                label="Nome"
                placeholder="Nome do item"
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="composition.code"
                label="Código"
                placeholder="Código do item"
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="composition.price"
                label="Custo"
                v-mask="['#,##', '##,##', '###,##']"
                placeholder="Custo do item"
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="composition.vendor_id"
                label="Fornecedor"
                :items="vendors"
                item-text="company_name"
                item-value="id"
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="composition.description"
                label="Descrição"
                auto-grow
                outlined
                dense
                rounded
                rows="10"
                row-height="15"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="2"
        >
          <v-row>
            <v-btn
              @click="onButtonClick"
              class="primary"
              rounded
              dense
            >
              Adicionar imagem
              <v-icon
                class="ml-4"
                small
                >fas fa-image</v-icon
              >
            </v-btn>
            <input
              ref="uploader"
              id="composition_image"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
            <v-col
              rows="12"
              md="2"
            >
              <v-avatar
                class="u-cursor-pointer"
                width="120px"
                height="170px"
                rounded
                color="grey lighten-4 elevation-2"
              >
                <img
                  v-if="
                    composition.composition_image != null ||
                    compositionImage != null
                  "
                  :src="
                    compositionImage != null
                      ? compositionImage
                      : $store.state.imagePath + composition.composition_image
                  "
                  class="c-profile-picture"
                  alt="foto de perfil"
                />

                <v-icon
                  v-else
                  color="primary"
                >
                  fas fa-image</v-icon
                >
              </v-avatar>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  props: {
    composition: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
  },
  data: () => ({
    vendors: [],
    compositionImage: null,
    profile: null,
  }),
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    getVendors() {
      this.$api
        .get('/vendors')
        .then((res) => {
          this.vendors = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.composition.composition_image = e.target.files[0]
      this.compositionImage = URL.createObjectURL(e.target.files[0])
    },
    clearFileField() {
      this.composition.composition_image = null
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }

    this.getVendors()
  },
  mounted() {
    if (this.composition.composition_image) {
      this.compositionImage = this.composition.composition_image
    }
  },
}
</script>
